export default defineStore('NewsletterModalStore', {
    state: () => ({
        isOpenValue: false,
        email: '',
    }),

    actions: {
        open() {
            this.isOpenValue = true;
        },

        close() {
            this.isOpenValue = false;
        },
        toggle() {
            this.isOpenValue = !this.isOpenValue;
        },
        setEmail(value) {
            this.email = value;
        },
    },

    getters: {
        getOpenState(state) {
            return state.isOpenValue;
        },
        getEmail(state) {
            return state.email;
        },
    },
});
