<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <div
    class="atom-input atom-input__wrapper"
    :class="{ 'has-options-open': showOptions }"
  >
    <div
      ref="wrapperRef"
      class="atom-input-multiselect__wrapper"
      @mouseenter="showOptionsOnHover(true)"
      @mouseleave="showOptionsOnHover(false)"
    >
      <div
        class="atom-input-multiselect"
        :class="{
          'has-input': hasInput,
          'is-disabled': isDisabled,
          'has-error': showErrors,
        }"
        @keypress="toggleOptions(false)"
        @click="toggleOptions(false)"
      >
        <label class="atom-input-multiselect__label" :for="name">
          {{ label + (isRequired ? ' *' : '') }}
        </label>

        <IonIcon
          class="atom-input-multiselect__icon has-pointer is-right"
          icon-name="chevron-down"
        />
      </div>
      <div
        class="atom-multiselect__options-wrapper"
        :class="{ 'is-visible': showOptions }"
      >
        <div
          ref="optionsRef"
          class="atom-input-multiselect__options"
        >
          <div
            v-for="option in options"
            :key="option.value"
            class="atom-input-multiselect__option"
            :class="{ 'is-selected': multiselectedOptions.includes(option.value) }"
          >
            <button
              type="button"
              class="atom-input-multiselect__option-button"
              @click="multiselectOption(option)"
              @keypress="multiselectOption(option)"
            >
              <span class="atom-input-multiselect__option-background" />
              <span class="atom-input-multiselect__option-label">{{ option.name }}</span>
              <IonIcon
                class="atom-input-multiselect__icon-close has-pointer is-right"
                icon-name="close"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="atom-input-multiselect__selected">
      <div
        v-for="option in options"
        :key="option.value"
        class="atom-input-multiselect__selected-option"
        :class="{ 'is-selected': multiselectedOptions.includes(option.value) }"
      >
        <button
          type="button"
          class="atom-input-multiselect__selected-option-button"
          @click="multiselectOption(option)"
        >
          <span class="atom-input-multiselect__selected-option-background" />
          <span class="atom-input-multiselect__selected-option-label">{{ option.name }}</span>
          <IonIcon
            class="atom-input-multiselect__icon-close has-pointer is-right"
            icon-name="close"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
/**
 * This component provides a multiselect input field with label, icon, and validation support.
 *
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.initialValue=''] - The initial value of the input field.
 * @param {array} [props.options=[]] - An array of options to display in the multiselect field.
 * @param {array} [props.validations=[]] - An array of validation rules.
 * @param {boolean} [props.isDisabled=false] - Whether the input field is disabled.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

import { useWindowSize, onClickOutside } from '@vueuse/core';

const props = defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: String,
        default: '',
        validator: (value) => typeof value === 'string',
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    defaultValue: {
        type: String,
        default: '',
        validator: (value) => typeof value === 'string',
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

/*
    Intialze formfield
*/
const wrapperRef = ref(null);
const errors = ref([]);
const inputValue = ref('');

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error']);

const setInitialValue = () => {
    if (props.defaultValue !== undefined
    || props.defaultValue !== null
    || props.defaultValue !== '') {
        const { validationErrors } = useFormfieldEmit(
            [props.defaultValue],
            props.name,
            emit,
            props.validations,
        );

        errors.value = validationErrors;
    } else {
        inputValue.value = props.initialValue;
    }
};

setInitialValue();

/*
    States
*/
const {
    hasInput,
    showErrors,
    isRequired,
} = useFormFieldStates(inputValue, errors, props);

/*
    Custom Logic
*/
const optionsRef = ref(null);
const closedHeight = '4px';
const optionsHeight = ref(closedHeight);
const checkIfAllOptionsSelected = (optionValue) => {
    const selectAllOption = props.options.filter(
        (option) => option.value === optionValue && option?.select_all_options,
    );

    const isSelectAllOption = selectAllOption.length !== 0;
    if (!isSelectAllOption) return { selectAll: false, restOptions: false };
    const restOptions = props.options.map(
        (option) => !option?.select_all_options && option.value,
    ).filter((option) => option);

    return { selectAll: selectAllOption, restOptions };
};

/* handle multiselected options */
const multiselectedOptions = ref([]);
const multiselectOption = (option) => {
    // add option to multiselectedOptions
    if (!multiselectedOptions.value.includes(option.value)
    && checkIfAllOptionsSelected(option.value).selectAll) {
        // add all options to multiselectedOptions
        multiselectedOptions.value = checkIfAllOptionsSelected(option.value).restOptions;
    } else if (!multiselectedOptions.value.includes(option.value)
    && !checkIfAllOptionsSelected(option.value).selectAll) {
        // add option to multiselectedOptions
        multiselectedOptions.value.push(option.value);
    } else {
        multiselectedOptions.value = multiselectedOptions.value.filter(
            (item) => item !== option.value,
        );
    }
};

/* show and hide options */
const showOptions = ref(false);
const toggleOptions = (forceClose = false) => {
    showOptions.value = forceClose ? false : !showOptions.value;
    optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
};

const showOptionsOnHover = (value) => {
    showOptions.value = value;
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    if (showOptions.value) {
        toggleOptions();
    }
});

/* Watch input */
const handleData = () => {
    if (props.isDisabled) return;

    if (Array.isArray(multiselectedOptions?.value)
        && multiselectedOptions.value.length === 0
        && props.defaultValue !== undefined
        && props.defaultValue !== null
        && props.defaultValue !== '') {
        const { validationErrors } = useFormfieldEmit(
            [props.defaultValue],
            props.name,
            emit,
            props.validations,
        );

        errors.value = validationErrors;
    } else {
        const { validationErrors } = useFormfieldEmit(
            multiselectedOptions.value,
            props.name,
            emit,
            props.validations,
        );

        errors.value = validationErrors;
    }
};

watchEffect(() => {
    handleData();
});

handleData(inputValue.value);

/* Watch window width to set height of box accordingly on resize */
const { width } = useWindowSize();
watch(() => width.value, () => {
    if (!props.isFullScreen) {
        optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
    }
});

const colorTopLevel = inject('colorSchema');
const colorTextSelected = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const colorText = computed(() => `var(--c-${colorTopLevel.value}-6)`);
const colorBackground = computed(() => `var(--c-${colorTopLevel.value}-13)`);
</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;
    flex-direction: column;
    user-select: none;
}
.atom-input-multiselect__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    cursor: pointer;
}

.atom-input-multiselect {
    @include formFieldBasicsText;

    position: relative;
    display: flex;
    max-width: 100%;
    align-items: flex-end;
    background: v-bind(colorBackground);
}

.atom-input-multiselect__label {
    @include formFieldLabelText;

    display: flex;
    height: 30px;
    align-items: flex-end;
    padding-right: 38px;
    margin-bottom: 0;
    color: v-bind(colorText);
    cursor: pointer;
    pointer-events: none;
}

.atom-input-multiselect__input {
    @include formFieldMultiselect;

    pointer-events: none;
}

.atom-input-multiselect__icon {
    @include formFieldInputIcon(false);

    top: 6px;
    right: 10px !important;
    width: 18px;
    height: 18px;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .has-options-open & {
        transform: rotate(180deg);
    }
}
.atom-input-multiselect__trigger {
    @include z-index('selectTrigger');

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    cursor: pointer;
}

.atom-multiselect__options-wrapper {
    @include z-index('selectTrigger');

    position: absolute;
    top: 30px;
    display: none;
    width: 100%;

    &.is-visible {
        display: block;
    }
}

.atom-multiselect__options {
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: height 0.5s $EASE_OUT--QUINT;

    .has-options-open & {
        height: 100%;
    }
}

.atom-input-multiselect__option {

    position: relative;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-input-multiselect__selected-option {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-top: 3px;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-input-multiselect__option-divider {
    display: block;
    width: calc(100% - 40px);
    height: 2px;
    margin: 0 auto;
}

.atom-input-multiselect__option-button {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: flex-end;
}

.atom-input-multiselect__selected-option-button {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: flex-end;
}

.atom-input-multiselect__option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(colorBackground);
    transition: background-color 0.2s ease-in-out;
}

.atom-input-multiselect__selected-option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(colorBackground);
    transition: background-color 0.2s ease-in-out;
}

.atom-input-multiselect__option-label {
    @include z-index('selectLabel');

    position: relative;
    padding-right: 38px;
    color: v-bind(colorText);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);

    .is-selected & {
        color: v-bind(colorTextSelected);
    }
}

.atom-input-multiselect__selected-option-label {
    @include z-index('selectLabel');

    position: relative;
    padding-right: 38px;
    color: v-bind(colorText);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);

    .is-selected & {
        color: v-bind(colorTextSelected);
    }
}

.atom-input-multiselect__icon-close {
    @include formFieldInputIcon(false);
    @include z-index('selectLabel');

    top: 6px;
    right: 10px !important;
    width: 18px;
    height: 18px;
    opacity: 0;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .is-selected & {
        opacity: 1;
    }

}

.atom-input-multiselect__selected {
    .atom-input-multiselect__selected-option {
        display: none;

        &.is-selected {
            display: flex;
        }
    }
}
</style>
