<template>
  <div class="block-grid">
    <slot />

    <div
      v-if="isStorybook"
      class="block-grid-overlay__toggle"
      @click="showGrid = !showGrid"
      @keypress="showGrid = !showGrid"
    >
      <span v-if="showGrid">Hide grid</span>
      <span v-else>Show grid</span>
    </div>

    <UtilGridOverlay
      v-if="isStorybook && showGrid"
      :columns-sm="columnsSm"
      :columns-md="columnsMd"
      :columns-lg="columnsLg"
    />
  </div>
</template>

<script setup>
defineProps({
    columnsSm: {
        type: String,
        default: 'var(--grid-columns)',
        validator: (value) => typeof value === 'string',
    },

    columnsMd: {
        type: String,
        default: 'var(--grid-columns)',
        validator: (value) => typeof value === 'string',
    },

    columnsLg: {
        type: String,
        default: 'var(--grid-columns)',
        validator: (value) => typeof value === 'string',
    },
});

const showGrid = ref(false);
const isStorybook = process.env.IS_STORYBOOK;
</script>

<style lang="scss" scoped>
.block-grid {
    @include grid-layout();

    display: grid;
    width: 100%;
    flex-wrap: wrap;
    column-gap: var(--grid-gutter);
    columns: v-bind('columnsLg');
    row-gap: var(--grid-row--gap);

    .block-hero & {
        row-gap: 0;
    }

    @include tablet {
        columns: v-bind('columnsMd');
    }

    @include mobile {
        columns: v-bind('columnsSm');
    }
}

.block-grid-overlay__toggle {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    padding: 4px 10px;
    border: 0 none;
    border-radius: 0 4px 0  0;
    border-top: 1px solid hsla(203, 50%, 30%, 0.15);
    border-right: 1px solid hsla(203, 50%, 30%, 0.15);
    margin: 0;
    background: #FFFFFF;
    -webkit-box-align: center;
    color: #2E3438;
    cursor: pointer;
    -ms-flex-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
}
</style>
