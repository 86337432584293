export default defineStore('FlyoutStore', {
    state: () => ({
        flyoutDataMain: null,
        flyoutDataSide: null,
        flyoutDataContact: null,
        flyoutColor: null,
        flyoutDepartmentName: null,
        flyoutRegionName: null,
        flyoutRegionCountry: null,
        flyoutNavName: null,
        flyoutHistory: [],
        isInternational: false,
        isOpen: false,
    }),

    actions: {
        setFlyoutDataMain(data, contentSide) {
            this.isOpen = true;

            if (data) {
                this.setFlyoutHistory(data, contentSide);
                this.flyoutDataMain = data;
            }

            // remove last data from history data is null
            if (!data) {
                // set the last data from history
                if (this.flyoutHistory.length > 0) {
                    // delete last data from history
                    this.flyoutHistory.pop();

                    // set data to last data from history
                    this.flyoutDataMain = this.flyoutHistory[this.flyoutHistory.length - 1];
                    if (this.flyoutHistory[this.flyoutHistory.length - 1]?.content_side) {
                        // eslint-disable-next-line vue/max-len
                        this.flyoutDataSide = this.flyoutHistory[this.flyoutHistory.length - 1].content_side;
                    } else {
                        this.flyoutDataSide = null;
                    }
                }

                // if history is empty set data to null and reset color
                if (this.flyoutHistory.length === 0) {
                    this.flyoutDataMain = null;
                    this.flyoutColor = null;
                    this.flyoutDataContact = null;
                    this.flyoutDataSide = null;
                }

                // reset region name
                this.flyoutRegionCountry = null;
            }
        },

        setFlyoutDataSide(data) {
            this.flyoutDataSide = data;
        },

        setFlyoutDataContact(image, title, text, link, linkLabel) {
            this.flyoutDataContact = {
                contact_image: image,
                contact_title: title,
                contact_text: text,
                contact_link: link,
                contact_link_label: linkLabel,
            };
        },

        setFlyoutColor(data) {
            this.flyoutColor = data;
        },

        setFlyoutDepartmentName(data) {
            this.flyoutDepartmentName = data;
        },

        setFlyoutRegionName(data) {
            this.flyoutRegionName = data;
        },

        setFlyoutNavName(data) {
            this.flyoutNavName = data;
        },

        resetFlyoutData() {
            this.flyoutDataMain = null;
            this.flyoutDataSide = null;
            this.flyoutDataContact = null;
            this.flyoutColor = null;
            this.flyoutDepartmentName = null;
            this.flyoutNavName = null;
            this.flyoutHistory = [];
            this.isInternational = false;
        },

        // set flyout history to navigate back
        setFlyoutHistory(data, contentSide) {
            // map data if side content is available
            if (contentSide) {
                this.flyoutHistory.push({
                    ...data,
                    content_side: contentSide,
                });
                return;
            }

            this.flyoutHistory.push(data);
        },

        setFlyoutIsInternational(data) {
            this.isInternational = data;
        },

        setFlyoutRegionCountry(data) {
            this.flyoutRegionCountry = data;
        },

        setFlyoutState(data) {
            this.isOpen = data;
        },
    },

    getters: {
        getFlyoutDataMain(state) {
            return state.flyoutDataMain;
        },

        getFlyoutDataSide(state) {
            return state.flyoutDataSide;
        },

        getFlyoutDataContact(state) {
            return state.flyoutDataContact;
        },

        getFlyoutColor(state) {
            return state.flyoutColor;
        },

        getFlyoutDepartmentName(state) {
            return state.flyoutDepartmentName;
        },

        getFlyoutRegionName(state) {
            return state.flyoutRegionName;
        },

        getFlyoutNavName(state) {
            return state.flyoutNavName;
        },

        getFlyoutIsInternational(state) {
            return state.isInternational;
        },

        getFlyoutRegionCountry(state) {
            return state.flyoutRegionCountry;
        },

        getFlyoutState(state) {
            return state.isOpen;
        },
    },
});
