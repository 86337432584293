<template>
  <div class="storyblok-input-multiselect">
    <AtomInputMultiselect
      :options="options"
      :label="label"
      :name="name"
      :default-value="defaultValue"
      @set-input="handleData"
    />
  </div>
</template>

<script setup>
defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: String,
        default: '',
        validator: (value) => typeof value === 'string',
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    defaultValue: {
        type: String,
        default: '',
        validator: (value) => typeof value === 'string',
    },
});

const emit = defineEmits(['set-input', 'set-error']);

const handleData = (data) => {
    emit('set-input', data);
};
</script>
