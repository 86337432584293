<template>
  <div class="atom-input atom-input__wrapper">
    <div
      class="atom-input-text"
      :class="{
        'has-input': hasInput,
        'is-disabled': isDisabled,
        'has-error': showErrors,
        secondary: appearance === 'secondary',
      }"
    >
      <label class="atom-input-text__label" :for="name">
        {{ label + (isRequiredVal ? ' *' : '') }}
      </label>

      <input
        :id="name"
        v-model="inputValue"
        :name="name"
        :required="isRequiredVal"
        :type="computedInputType"
        :placeholder="placeholder"
        class="atom-input-text__input"
        @focus="setFocus(true)"
        @focusout="setFocus(false)"
        @mouseenter="setHover(true)"
        @mouseleave="setHover(false)"
      >
    </div>
  </div>
</template>

<script setup>
/**
 * This component provides an input field with label, icon, and validation support.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.initialValue=''] - The initial value of the input field.
 * @param {string} [props.inputType='text'] - The type of input field.
 * @param {array} [props.validations=[]] - An array of validation rules.
 * @param {boolean} [props.isDisabled=false] - Whether the input field is disabled.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

const props = defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: true,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: String,
        default: '',
        validator: (value) => typeof value === 'string',
    },

    inputType: {
        type: String,
        default: 'text',
        validator: (value) => [
            'text',
            'email',
            'password',
        ].includes(value),
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    isRequiredVal: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    appearance: {
        type: String,
        default: '',
        validator: (value) => ['primary', 'secondary', ''].includes(value),
    },

    placeholder: {
        type: String,
        default: '',
    },

    color: {
        type: String,
        default: '',
    },
});

/*
    Intialze formfield
*/
const errors = ref([]);
const inputValue = ref('');
const modalStore = useNewsletterModalStore();
const emailValue = computed(() => modalStore.getEmail);

const setInitialValue = () => {
    inputValue.value = props.initialValue;
    if (props.inputType === 'email' && emailValue.value) {
        inputValue.value = emailValue.value;
    }
};

setInitialValue();

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error']);

/* Watch input */
const handleData = (value) => {
    if (props.isDisabled) return;

    const { validationErrors } = useFormfieldEmit(
        value,
        props.name,
        emit,
        props.validations,
    );

    emit('set-input', value);

    errors.value = validationErrors;
};
watch(() => inputValue.value, (newValue) => {
    handleData(newValue);
});

handleData(inputValue.value);

/*
    States
*/
const {
    setHover,
    setFocus,
    hasInput,
    showErrors,
} = useFormFieldStates(inputValue, errors, props);

/*
    Custom values for this kind of input
*/
/* Input Type */

const computedInputType = computed(() => {
    /* If input is password, return text or password */
    if (props.inputType === 'password') {
        return 'password';
    }

    /* Else, return input type */
    return props.inputType;
});

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const colorTextfield = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss">
.atom-input__wrapper {
    @include formFieldWrapper;
}

.atom-input-text {
    @include formFieldBasicsText;
}

.atom-input-text__label {
    @include formFieldLabelText;
    color: v-bind(colorText);

    .block-download-hub__search & {
        display: none;
    }
    .secondary & {
        display: none
    }
}

.atom-input-text__input {
    @include formFieldInputText;

    border-bottom: solid 2px v-bind(colorText);
    color: v-bind(colorTextfield);

    .block-download-hub__search-input & {
        margin-bottom: 0
    }

    .secondary & {
        @include formFieldInputTextSecondary;

        border: 1px solid v-bind(colorTextfield);
        color: v-bind(colorTextfield);
        font-family: var(--f-family--thin);
        &::placeholder {
            color: v-bind(colorTextfield);
        }
    }
    .block-item-form-calculator & {
        border-bottom: solid 2px v-bind(color) !important;
        color: v-bind(color) !important;
        &::placeholder {
            color: v-bind(color) !important;
        }
    }
}

.atom-input-text__icon {
    @include formFieldInputIcon;
}

</style>
