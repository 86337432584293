<template>
  <h1 v-if="htmlTag === 'h1'" class="atom-heading" :class="[`is-size-${fontSize}`, `is-color-${colorSchema}`]">
    {{ text }}
  </h1>
  <h2 v-else-if="htmlTag === 'h2'" class="atom-heading" :class="[`is-size-${fontSize}`, `is-color-${colorSchema}`]">
    {{ text }}
  </h2>
  <h3 v-else-if="htmlTag === 'h3'" class="atom-heading" :class="[`is-size-${fontSize}`, `is-color-${colorSchema}`]">
    {{ text }}
  </h3>
  <h4 v-else-if="htmlTag === 'h4'" class="atom-heading" :class="[`is-size-${fontSize}`, `is-color-${colorSchema}`]">
    {{ text }}
  </h4>
  <h5 v-else-if="htmlTag === 'h5'" class="atom-heading" :class="[`is-size-${fontSize}`, `is-color-${colorSchema}`]">
    {{ text }}
  </h5>
  <h6 v-else-if="htmlTag === 'h6'" class="atom-heading" :class="[`is-size-${fontSize}`, `is-color-${colorSchema}`]">
    {{ text }}
  </h6>
  <div v-else class="atom-heading" :class="[`is-size-${fontSize}`, `is-color-${colorSchema}`]">
    {{ text }}
  </div>
</template>

<script setup>
const props = defineProps({
    htmlTag: {
        type: String,
        default: 'h2',
    },

    fontSize: {
        type: String,
        default: 'h2',
    },

    colorSchema: {
        type: String,
        default: 'petrol',
    },

    text: {
        type: String,
        required: true,
        default: '',
        validator: (value) => value.length > 0,
    },
});

const colorTopLevel = inject('colorSchema');

const colorMain = computed(() => (
    colorTopLevel.value === 'petrol'
        ? `var(--c-${props.colorSchema}-1)`
        : `var(--c-${colorTopLevel.value}-1)`
));
</script>

<style lang="scss" scoped>
.atom-heading {
    color: v-bind(colorMain);
    font-family: var(--f-family--regular);

    /*
        Font size
    */
    &.is-size-h1 {
        font-size: var(--f-size--h1);
        line-height: var(--l-height--h1)
    }

    &.is-size-h2 {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--h2);
        line-height: var(--l-height--h2);
    }

    &.is-size-h3 {
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);
    }
    &.is-size-h3-atom {
        font-family: var(--f-family--black);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);
    }
    &.is-size-h4-atom {
        font-family: var(--f-family--thin);
        font-size: var(--f-size--h4--atom);
        line-height: var(--l-height--h4--atom);

    }

    &.layout-news__hero-article-subtitle {
        font-family: var(--f-family--thin);
    }
}
</style>
