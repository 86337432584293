export default defineStore('ModalboxStore', {
    state: () => ({
        isOpenValue: false,
    }),

    actions: {
        openModalbox() {
            this.isOpenValue = true;
            document.body.classList.add('no-scroll');
        },

        closeModalbox() {
            this.isOpenValue = false;
            document.body.classList.remove('no-scroll');
        },
    },

    getters: {
        isOpen(state) {
            return state.isOpenValue;
        },
    },
});
