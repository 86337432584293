<template>
  <div
    :class="{
      'has-legend': parsedLegend || source,
    }"
    class="atom-image__wrapper"
  >
    <div class="atom-image__background">
      <NuxtImg
        v-if="!isSvg"
        :class="[
          isLoaded ? 'is-loaded' : '',
          lazy ? 'has-lazy' : '',
        ]"
        class="atom-image"
        :loading="lazy ? 'lazy' : 'eager'"
        v-bind="{ ...mergedProps }"
        @load="showImage()"
      />
      <img v-else :src="mergedProps.src" :alt="mergedProps.alt" />
    </div>

    <div
      v-if="(parsedLegend && parsedLegend !== '<p></p>') || source"
      class="atom-image-legend"
      :class="{
        'is-only-legend': parsedLegend && !source,
      }"
    >
      <div class="atom-image-legend__icon">
        <div class="atom-image-legend__icon-wrapper">
          <IonIcon
            class="atom-image-legend__icon-svg"
            icon-name="info"
          />
        </div>
      </div>

      <div class="atom-image-legend__content">
        <AtomRichtext
          v-if="legend"
          class="atom-image-legend__text"
          :html="parsedLegend"
          size="small"
        />

        <div class="atom-image-legend__source">
          {{ source }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
    /* Image Data */
    image: {
        type: Object,
        required: true,
        validator: (value) => typeof value === 'object',
    },

    provider: {
        type: String,
        default: 'ipx',
        required: true,
        validator: (value) => ['ipx', 'storyblok'].includes(value),
    },

    /* Options */
    width: {
        type: [String, Boolean, Number],
        default: '1000px',
    },

    height: {
        type: [String, Boolean, Number],
        default: '',
    },

    aspectRatio: {
        type: [String, Boolean],
        default: false,
    },

    lazy: {
        type: Boolean,
        default: true,
    },

    lazyDelay: {
        type: Number,
        default: 0,
    },

    background: {
        type: [String, Boolean],
        default: false,
    },

    preload: {
        type: Boolean,
        default: false,
    },

    format: {
        type: String,
        default: 'webp',
        validator: (value) => ['webp', 'jpg', 'png'].includes(value),
    },

    smartCrop: {
        type: Boolean,
        default: false,
        validator: (value) => typeof (value) === 'boolean',
    },

    focus: {
        type: [String, Boolean],
        default: false,
    },

    source: {
        type: String,
        default: '',
    },

    legend: {
        type: Object,
        default: null,
    },
});

/*
    Lazy loading
*/
const isLoaded = ref(false);
const showImage = async () => {
    if (props.lazy) {
        await sleep(props.lazyDelay);
    }
    isLoaded.value = true;
};

/* Lazy loading background */
const backgroundColor = computed(() => {
    if (props.background) {
        return props.background;
    }

    return 'transparent';
});

/*
    Build props for Nuxt Image component
*/
const generalProps = computed(() => ({
    provider: props.provider,
    loading: props.lazy ? 'lazy' : 'eager',
    preload: props.preload,
}));

const buildStoryblokProps = () => {
    const modifiers = {
        filters: {},
    };

    if (props.smartCrop) {
        modifiers.smart = true;
    }

    if (props.focus && !props.smartCrop) {
        modifiers.filters.focal = props.focus;
    }

    if (props.image.focus) {
        modifiers.filters.focal = props.image.focus;
    }

    const dimensions = {
        width: props.width ? props.width : null,
        height: props.height ? props.height : null,
    };

    if (props.aspectRatio) {
        const width = parseInt(props.width, 10);
        const [ratioX, ratioY] = props.aspectRatio.split(':');
        dimensions.width = `${width}px`;
        dimensions.height = `${(width / ratioX) * ratioY}px`;
    }

    return {
        src: props.image?.filename,
        alt: props.image?.alt,
        format: props.format,
        modifiers,
        ...dimensions,
    };
};

const buildIpxProps = () => ({
    src: props.image?.src,
    alt: props.image?.alt,
    width: props.width ? props.width : null,
    height: props.height ? props.height : null,
});

const imageProps = computed(() => {
    switch (props.provider) {
    case 'storyblok':
        return buildStoryblokProps();
    case 'ipx':
    default:
        return buildIpxProps();
    }
});

const mergedProps = computed(() => ({
    ...generalProps.value,
    ...imageProps.value,
}));

/*
    Check for svg
*/
const isSvg = computed(() => mergedProps.value?.src?.includes('.svg'));

/*
    Parsed legend
*/
const { richTextResolver } = useStoryblokApi();
const parsedLegend = computed(() => (props.legend ? richTextResolver.render(props.legend) : null));

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.atom-image__wrapper {
    display: flex;

    &.has-legend {
        position: relative;
        overflow: hidden;
        flex-direction: column;
    }

    .block-flyout-contact__content-main-contact &{
        @include tablet {
            display: none;
        }
    }
}

.atom-image__background {
    display: flex;
    height: 100%;
    align-self: flex-start;
    background: v-bind(backgroundColor);

    img {
        .block-item-contact-card &,
        .detail-card & {
            @include tablet {
                aspect-ratio: 1/1;
            }
        }
    }

    .storyblok-image & {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .block-download-hub__filter-primary-departments-image & {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .block-key-figures__image-section & {
        width: 100%;
        height: 100%;
    }

    .block-form-contact__person-card-image & {
        width: 100%;
    }
}

.atom-image {
    object-fit: cover;

    // &.has-lazy {
    //     opacity: 0;
    //     transition: opacity 0.3s ease-in-out;
    // }

    // &.is-loaded {
    //     opacity: 1;
    // }

    .block-download-hub__filter-primary-departments-image & {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .block-key-figures__image-section & {
        width: 100%;
        height: 100%;
    }

    .block-form-contact__person-card-image & {
        width: 100%;
        height: 360px;

        @include tablet {
            height: 100%;
        }
    }

    .block-form-contact__person-card-image.single & {
        width: 100%;
        height: 360px;

        @include tablet {
            height: 100%;
            object-position: top;
        }
    }

    .block-form-contact__person-card-image.single.is-logo & {
        width: 100%;
        height: 360px;

        @include tablet {
            height: 100%;
            object-position: unset;
        }
    }
}

.atom-image-legend__content {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    padding-left: 40px;
    opacity: 0;
    row-gap: 15px;
    transition: opacity 0.3s ease-out;
    will-change: opacity;

    @include mobile {
        padding-left: 0;
        opacity: 1;

        &.is-only-legend {
            display: none;
        }
    }
}

.atom-image-legend__icon-wrapper {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.atom-image-legend__icon {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 40px;
    height: 100%;
    justify-content: center;
    background-color: var(--legend-background);
    transition: background-color 0.3s $EASE_OUT--QUAD;

    @include mobile {
        display: none;
    }
}

.atom-image-legend {
    --legend-background: rgba(255, 255, 255, 0.95);
    --transparent-color: rgba(255, 255, 255, 0);

    @include mobile {
        --transparent-color: var(--legend-background);

        &.is-only-legend {
            display: none;
        }
    }

    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: 10px;
    padding-right: 40px;
    padding-bottom: 20px;
    background-color: var(--transparent-color);
    column-gap: 10px;
    transform: translateY(calc(100% - 40px));
    transition: transform 0.3s $EASE_OUT--QUAD, background-color 0.3s $EASE_OUT--QUAD;

    &:hover {
        background-color: var(--legend-background);
        transform: translateY(0);
        .atom-image-legend__content {
            opacity: 1;
        }

        .atom-image-legend__icon {
            background-color: var(--transparent-color);
        }
    }

    @include mobile {
        position: relative;
        padding: 20px;
        transform: none;
    }
}

.atom-image-legend__icon-svg {
    width: 22px;
    height: 22px;
}

.atom-image-legend__text {
    @include mobile {
        display: none;
    }
}

.atom-image-legend__source {
    color: v-bind(color1);
    font-family: var(--f-family--thin);
    font-size: 12px;
    line-height: 19px;
}
</style>
