<template>
  <div class="util-wrapper-layout">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.util-wrapper-layout {
    position: relative;
    width: 100%;
    max-width: calc(var(--grid-max-width) + var(--page-padding) * 2);
    padding-right: var(--page-padding);
    padding-left: var(--page-padding);
    margin: 0 auto;

    &.storyblok-map-international__wrapper-layout {
        @include mobile {
            padding: 0;
        }
    }
}
</style>
