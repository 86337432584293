<template>
  <NuxtLink
    v-if="computedLink.isInternal"
    v-bind="computedLink"
    class="util-route-link"
  >
    <slot />
  </NuxtLink>
  <a
    v-else
    v-bind="computedLink"
    class="util-route-link"
  >
    <slot />
  </a>
</template>

<script setup>
const props = defineProps({
    link: {
        type: [String, Object],
        default: '',
    },

    target: {
        type: String,
        default: '_self',
        validator: (value) => ['_blank', '_self'].includes(value),
    },
});

const localePath = useLocalePath();
const { locale } = useI18n();

const checkIsExternal = (string) => string.includes('http://')
    || string.includes('https://')
    || string.includes('mailto:')
    || string.includes('tel:');

const computedLink = computed(() => {
    if (typeof props.link === 'object') {
        const link = usePrepareStoryblokLink(props.link, locale.value);
        const isInternal = !checkIsExternal(link);
        return {
            isInternal,
            ...(isInternal ? { to: link } : { href: link }),
            target: isInternal ? props.target : '_blank',
            rel: props.target === '_blank' ? 'noopener noreferrer' : null,
        };
    }

    // Add mailto: to email links
    if (props.link.includes('@')) {
        return {
            isInternal: false,
            href: props.link,
            target: '_blank',
            rel: 'noopener noreferrer',
        };
    }

    return {
        isInternal: !checkIsExternal(props.link),
        href: localePath(props.link),
        target: props.target,
        rel: props.target === '_blank' ? 'noopener noreferrer' : null,
    };
});
</script>
