export default (link, locale) => {
    if (typeof link === 'string') return link;

    const { cached_url: cachedUrl } = link;

    if (cachedUrl.includes('http://') || cachedUrl.includes('https://')) {
        return cachedUrl;
    }

    const alternates = link?.story?.alternates;
    let translatedSlug = null;

    if (alternates && locale) {
        const alternate = alternates.find((alt) => alt.lang === locale);
        translatedSlug = alternate ? `/${alternate.lang}/${alternate.path}` : null;
    }

    const translatedUrl = translatedSlug || cachedUrl;
    if (!translatedUrl) return '';

    const replacedPath = translatedUrl.replace(`/${locale.value}`, '');

    let urlString = replacedPath === '/home' ? '/' : translatedUrl;
    urlString = urlString[0] === '/' ? urlString.substr(1) : urlString;

    // Remove trailing slash if it exists
    urlString = urlString.endsWith('/') ? urlString.slice(0, -1) : urlString;

    const linkPrefix = link.linktype === 'email' ? 'mailto:' : '/';
    const linkSuffix = link.anchor ? `#${link.anchor}` : '';
    const linkSlash = link.linktype !== 'email'
    && !urlString.includes('http://')
    && !urlString.includes('https://') ? '/' : '';

    return `${linkPrefix}${urlString}${linkSlash}${linkSuffix}`;
};
