<template>
  <div class="block-grid-overlay">
    <div class="block-grid-overlay__padding" />
    <div class="block-grid-overlay__grid">
      <div
        v-for="i in activeColumn"
        :key="i"
        class="block-grid-overlay__column"
      >
        {{ i }}
      </div>
    </div>
    <div class="block-grid-overlay__padding" />
  </div>
</template>

<script setup>
const props = defineProps({
    columnsSm: {
        type: String,
        default: 'var(--grid-columns)',
        validator: (value) => typeof value === 'string',
    },

    columnsMd: {
        type: String,
        default: 'var(--grid-columns)',
        validator: (value) => typeof value === 'string',
    },

    columnsLg: {
        type: String,
        default: 'var(--grid-columns)',
        validator: (value) => typeof value === 'string',
    },
});

const parseColumn = (string) => {
    if (string.includes('var(')) {
        const variableName = string.replace('var(', '').replace(')', '');
        return parseInt(getCssVar(variableName), 10);
    }

    return parseInt(string, 10);
};

const columns = computed(() => ({
    sm: parseColumn(props.columnsSm),
    md: parseColumn(props.columnsMd),
    lg: parseColumn(props.columnsLg),
}));

const clientDeviceStore = useClientDeviceStore();
const activeColumn = computed(() => {
    if (clientDeviceStore.isMobile) {
        return columns.value.sm;
    }

    if (clientDeviceStore.isTablet) {
        return columns.value.md;
    }

    return columns.value.lg;
});
</script>

<style lang="scss" scoped>
.block-grid-overlay {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.block-grid-overlay__grid {
    display: flex;
    width: calc(100% - var(--page-padding) * 2);
    justify-content: space-between;
    column-gap: var(--grid-gutter);
}

.block-grid-overlay__column {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(250, 150, 0, 0.121);
    color: rgb(255, 140, 0);
    font-family: var(--f-family--regular);
    font-size: 12px;
}

.block-grid-overlay__padding {
    position: relative;
    display: flex;
    height: 100%;
    flex-basis: var(--page-padding);
    align-items: center;
    justify-content: center;
    background: rgba(114, 216, 94, 0.143);
    color: rgb(23, 176, 105);
    font-family: var(--f-family--regular);
}
</style>
