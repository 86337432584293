/**
 * This composable provides reactive properties and
 * functions for managing the state of a form field.
 *
 * @param {object} inputValue - The current input value.
 * @param {array} errors - An array of validation errors.
 * @param {object} props - The component props.
 * @returns {object} - An object with reactive properties and functions.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

export default (inputValue, errors, props) => {
    /* Hover state */
    const hasHover = ref(false);
    const setHover = (value) => {
        hasHover.value = value;
    };

    /* Focus */
    const hasFocus = ref(false);
    const initialFocusOut = ref(false);
    const setFocus = (value) => {
        hasFocus.value = value;

        if (!value && !initialFocusOut.value) {
            initialFocusOut.value = true;
        }
    };

    /* Input */
    const hasInput = computed(
    /* Move label up if input has value or is focused */
        () => inputValue.value.length > 0 || hasFocus.value,
    );

    /* Errors */
    const showErrors = computed(
    /* only show errors after first focus out */
        () => errors.value.length > 0 && initialFocusOut.value,
    );
    const showErrorList = computed(
    /* only show error list if input is hovered */
        () => showErrors.value && hasHover.value,
    );
    const isRequired = computed(() => Object.keys(props.validations).some(
    /* check if input is required */
        (validation) => validation === 'isNotEmpty',
    ));

    return {
        hasHover,
        setHover,
        hasFocus,
        setFocus,
        hasInput,
        showErrors,
        showErrorList,
        isRequired,
    };
};
